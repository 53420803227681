<template>
  <div
    class="navbar navbar-dark bg-blue navbar-expand-lg navbar-static border-bottom border-bottom-white border-opacity-10"
  >
    <div class="container-fluid ps-1">
      <div class="d-flex d-lg-none me-2">
        <button
          type="button"
          class="navbar-toggler sidebar-mobile-main-toggle rounded-pill"
        >
          <i class="ph-list"></i>
        </button>
      </div>
      <div class="navbar-brand">
        <router-link
          :to="`/home`"
          class="d-inline-flex align-items-center"
          title="Home"
        >
          <img
            src="@/assets/img/logos/orcamento.png"
            class="h-32px"
            alt="Logo do Ocupacional"
          />
        </router-link>
        <router-link
          :to="`/home`"
          class="navbar-nav-link align-items-center rounded-pill p-1 ms-3"
          title="Home"
        >
          Módulo de Orçamento - Grupo SN
        </router-link>
      </div>
      <div class="d-xl-none">
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-navigation"
          aria-expanded="true"
          title="Expandir/Recolher"
        >
          <i class="ph-list" />
        </button>
      </div>
      <div class="navbar-collapse collapse" id="navbar-navigation">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link
              :to="`/parameters`"
              class="navbar-nav-link rounded"
              title="Agenda"
            >
              <i class="ph ph-gear me-2"></i>
              Parametros
            </router-link>
          </li>
          <li class="nav-item dropdown ms-xl-1">
            <a
              href="#"
              class="navbar-nav-link rounded dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ user && user.name ? user.name : 'Usuário não identificado' }}
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a
                class="dropdown-item cursor-pointer"
                @click="logout"
                title="Sair"
              >
                <i class="ph-sign-out me-2" />Sair
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PATHS from '@/router/routesMap'

export default {
  name: 'Navbar',

  computed: {
    ...mapGetters(['user']),
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.WELCOME)
      })
    },
  },
  mounted() {},
}
</script>
