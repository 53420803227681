import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getSteps({ commit }, companyCode) {
      try {
        const response = await api.get(`/params/companies/${companyCode}/steps`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getStep({ commit }, data) {
      try {
        const response = await api.get(
          `/params/companies/${data.companyCode}/steps/${data.stepId}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getStepFillers({ commit }, data) {
      try {
        const response = await api.get(
          `/params/companies/${data.companyCode}/steps/${data.stepId}/fillers`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async createStep({ commit }, data) {
      try {
        console.log(data)
        const response = await api.post(
          `/params/companies/${data.companyId}/steps`,
          data.step,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async updateStep({ commit }, data) {
      try {
        console.log(data)
        const response = await api.put(
          `/params/companies/${data.companyId}/steps/${data.stepId}`,
          data.step,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
