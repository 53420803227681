import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getCompanies({ commit }, data) {
      try {
        const response = await api.get(`/params/companies`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getCompany({ commit }, code) {
      try {
        const response = await api.get(`/params/companies/${code}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
