import api from '@/services/api'

export default {
  state: {
    isLoggedInGsnDigitalBudgetModule: !!localStorage.getItem(
      'tokenGsnDigitalBudgetModule',
    ),
    userGsnDigitalBudget: {
      codUsuario: 0,
      name: '',
      profiles: {
        names: [],
        codes: [],
      },
    },
  },

  mutations: {
    AUTH(state, user) {
      state.isLoggedInGsnDigitalBudgetModule = true
      state.userGsnDigitalBudget = {
        code: user.code,
        name: user.name,
        profiles: user.profiles,
      }
    },

    LOGOUT(state) {
      state.isLoggedInGsnDigitalBudgetModule = false
      state.userGsnDigitalBudget = {
        codUsuario: 0,
        name: '',
        profiles: {
          names: [],
          codes: [],
        },
      }
    },
  },

  actions: {
    async signIn({ commit }, credentials) {
      try {
        const response = await api.post('/sign-in', {
          user: credentials.user,
          password: credentials.password,
        })

        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''

        let profilesName = []
        let profilesCode = []
        if (data && data.user.profiles.Orcamento) {
          profilesName = data.user.profiles.Orcamento.map(
            (profile) => profile.NOMEFUNCAO,
          )
          profilesCode = data.user.profiles.Orcamento.map(
            (profile) => profile.CODFUNCAO,
          )
        }
        const user = {
          codUsuario: data && data.user.codUsuario ? data.user.codUsuario : 0,
          name: data && data.user ? data.user.name : '',
          profiles: {
            names: profilesName,
            codes: profilesCode,
          },
        }

        localStorage.setItem('tokenGsnDigitalBudgetModule', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },

    logout: ({ commit }) => {
      commit('LOGOUT')
    },
  },

  getters: {
    isLoggedInGsnDigitalBudgetModule: (state) =>
      state.isLoggedInGsnDigitalBudgetModule,
    user: (state) => state.userGsnDigitalBudget,
    codUsuario: (state) => state.userGsnDigitalBudget.codUsuario,
    // Gestão
    profilesNames: (state) => state.userGsnDigitalBudget.profiles.names,
    profilesCodes: (state) => state.userGsnDigitalBudget.profiles.codes,
    // Metas
    readManagement: (state) =>
      state.userGsnDigitalBudget.profiles.names.includes('VisualizaGestao'),
    writeManagement: (state) =>
      state.userGsnDigitalBudget.profiles.names.includes('AtualizaGestao'),
  },
}
