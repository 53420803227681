import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getClientsList({ commit }, companyCode) {
      try {
        const response = await api.get(
          `/flows/${companyCode}/data/commercial/clients`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getCommercialBudget({ commit }, companyCode) {
      try {
        const response = await api.get(`/flows/${companyCode}/data/commercial`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async deleteCommercialForm({ commit }, data) {
      try {
        const response = await api.delete(
          `/flows/${data.companyCode}/data/commercial/${data.id}`,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async saveCommercialForm({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/commercial`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async finishCommercialForm({ commit }, data) {
      try {
        const response = await api.put(
          `/flows/${data.companyCode}/data/commercial/finish`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
