import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Welcome from '../views/welcome/Welcome.vue'

const routes = [
  {
    name: 'Welcome',
    path: PATHS.WELCOME,
    component: Welcome,
  },
  {
    name: 'SignIn',
    path: PATHS.SIGN_IN,
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/auth/SignInView.vue'),
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Companies',
    path: PATHS.COMPANIES,
    component: () =>
      import(
        /* webpackChunkName: "companies" */ '../views/companies/Companies.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'CompanyDetails',
    path: PATHS.COMPANIES_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "companies" */ '../views/companies/CompanyDetails.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'ParametersHome',
    path: PATHS.PARAMETERS,
    component: () =>
      import(
        /* webpackChunkName: "parameters" */ '../views/parameters/ParametersHome.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'ParametersCompanySteps',
    path: PATHS.PARAMETERS_COMPANY_STEPS,
    component: () =>
      import(
        /* webpackChunkName: "parameters" */ '../views/parameters/ParametersCompanySteps.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  const { isLoggedInGsnDigitalBudgetModule } = store.getters

  if (isLoggedInGsnDigitalBudgetModule && to.name === 'SignIn') {
    return { name: 'Home' }
  }
  // if (isLoggedInGsnDigitalBudgetModule && to.name === 'ParametersHome') {
  //   return { name: 'Home' }
  // }

  if (to.meta.requiresAuth) {
    if (
      !isLoggedInGsnDigitalBudgetModule &&
      !localStorage.getItem('tokenGsnDigitalBudgetModule')
    ) {
      return { name: 'Welcome' }
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedInGsnDigitalBudgetModule && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.WELCOME)
  }
})

export default router
