import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async createFiller({ commit }, data) {
      try {
        const response = await api.post(
          `/params/companies/${data.companyId}/steps/${data.stepId}/fillers`,
          data.filler,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async getFiller({ commit }, data) {
      try {
        const response = await api.get(
          `/params/companies/${data.companyCode}/steps/${data.stepId}/fillers/${data.fillerId}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async updateFiller({ commit }, data) {
      try {
        const response = await api.put(
          `/params/companies/${data.companyId}/steps/${data.stepId}/fillers/${data.filler.IDUSUARIO}`,
          data.filler,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async deleteFiller({ commit }, data) {
      try {
        const response = await api.delete(
          `/params/companies/${data.companyId}/steps/${data.stepId}/fillers/${data.fillerId}`,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
