const BASE_URL = '/'
const WELCOME = BASE_URL
const SIGN_IN = `${BASE_URL}sign-in`
const HOME = `${BASE_URL}home`
const COMPANIES = `${BASE_URL}companies`
const COMPANIES_DETAILS = `${BASE_URL}companies/:code`
const PARAMETERS = `${BASE_URL}parameters`
const PARAMETERS_COMPANY_STEPS = `${BASE_URL}parameters/companies/:code/steps`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  WELCOME,
  SIGN_IN,
  HOME,
  COMPANIES,
  COMPANIES_DETAILS,
  PARAMETERS,
  PARAMETERS_COMPANY_STEPS,
  UNAUTHORIZED,
}
