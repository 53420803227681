<template>
  <Navbar v-if="showComponent" />
  <div class="page-header" v-if="pageTitle">
    <div class="page-header-content d-lg-flex">
      <div class="d-flex">
        <h4 class="page-title mb-0">
          {{ pageTitle }}
        </h4>
      </div>

      <div
        class="d-lg-block my-lg-auto ms-lg-auto collapse"
        id="page_header"
        style=""
      ></div>
    </div>
  </div>
  <div
    class="page-content pt-0"
    :style="
      showBackground
        ? {
            backgroundImage:
              'url(' + require(`@/assets/img/backgrounds/${background}`) + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }
        : {}
    "
  >
    <div class="content-wrapper">
      <div class="content-inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/commons/globals/Navbar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Layout',

  components: {
    Navbar,
  },

  data() {
    return {
      routesNotShowNavbar: ['Welcome', 'SignIn', 'Unauthorized', 'NotFound'],
      routesShowBackground: ['Welcome', 'SignIn'],
    }
  },

  computed: {
    ...mapGetters(['background']),
    ...mapGetters(['pageTitle']),

    showBackground() {
      return this.routesShowBackground.includes(this.$route.name)
    },
    showComponent() {
      return !this.routesNotShowNavbar.includes(this.$route.name)
    },
    showSidebar() {
      return !this.routesNotShowSidebar.includes(this.$route.name)
    },
  },
}
</script>
