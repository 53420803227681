import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getAvaliableStepsOnFlow({ commit }, companyCode) {
      try {
        console.log('companyCode', companyCode)
        return await api.get(`/flows/${companyCode}/steps`)
        // return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
