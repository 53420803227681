import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getDraft({ commit }, data) {
      try {
        const response = await api.get(
          `/flows/${data.companyCode}/data/draft?stepId=${data.stepId}&formId=${data.formId}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getDraftByCompany({ commit }, data) {
      try {
        const response = await api.get(
          `/flows/${data.companyCode}/data/draft/final`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async saveDraft({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/draft`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
