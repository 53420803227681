<template>
  <div class="row vh-100 m-0 mt-lg-5 me-lg-5">
    <div
      class="d-flex align-items-center justify-content-center justify-content-lg-end"
    >
      <div class="card">
        <div class="card-body">
          <h4 class="text-secondary text-center">
            Orçamento Digital - Grupo SN
          </h4>
          <div class="row">
            <div class="col-12 w-100 text-center">
              <router-link
                to="/sign-in"
                class="btn btn-welcome"
                title="Acessar como Gestor"
              >
                <div>
                  <span class="">Acessar</span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',

  data() {
    return {
      backgroundImg: 'bg1.jpeg',
    }
  },

  mounted() {
    if (localStorage.getItem('sessionError')) {
      this.$root.$refs.notification.warning(
        localStorage.getItem('sessionError'),
      )
      localStorage.removeItem('sessionError')
    }
  },

  created() {
    this.$store.dispatch('setPageTitle', null)
    this.$store.dispatch('setBackground', this.backgroundImg)
  },
}
</script>

<style scoped>
.fs-icon {
  font-size: 5rem;
}

.btn-welcome {
  border: solid 1px;
  color: var(--secondary);
  min-width: 140px;
}

@media (max-width: 480px) {
  .btn-welcome {
    min-width: 0;
    width: 100%;
  }

  .btn-welcome p {
    font-size: 0.68rem;
  }
}

.btn-welcome:hover {
  border: 0;
  background-color: var(--secondary);
  color: #fff;
}
</style>
