import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async addAttachToForm({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/attachments`,
          data,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async getAllAttachments({ commit }, data) {
      try {
        const response = await api.get(
          `/flows/${data.companyCode}/attachments?formStepId=${data.formStepId}`,
        )
        return response && response.status ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
