/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-new */
/* eslint-disable no-restricted-syntax */
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'

window.bootstrap = bootstrap

// Setup module
// ------------------------------
const App = (function () {
  //
  // Detect OS to apply custom scrollbars
  //

  // Custom scrollbar style is controlled by CSS. This function is needed to keep default
  // scrollbars on MacOS and avoid usage of extra JS libraries
  const detectOS = function () {
    const { platform } = window.navigator
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const customScrollbarsClass = 'custom-scrollbars'

    // Add class if OS is windows
    if (windowsPlatforms.indexOf(platform) !== -1)
      document.documentElement.classList.add(customScrollbarsClass)
  }

  //
  // Transitions
  //

  // Disable all transitions
  const transitionsDisabled = function () {
    document.body.classList.add('no-transitions')
  }

  // Enable all transitions
  const transitionsEnabled = function () {
    document.body.classList.remove('no-transitions')
  }

  // Sidebars
  // -------------------------

  //
  // On desktop
  //

  // Resize main sidebar
  const sidebarMainResize = function () {
    // Elements
    // eslint-disable-next-line one-var
    const sidebarMainElement = document.querySelector('.sidebar-main'),
      sidebarMainToggler = document.querySelectorAll('.sidebar-main-resize'),
      sidebarMobileTogglerClass = 'sidebar-mobile-expanded',
      resizeClass = 'sidebar-main-resized',
      navSubmenuClass = 'nav-group-sub',
      navSubmenuReversedClass = 'nav-group-sub-reversed',
      submenuToggleClass = 'nav-group-sub-visible',
      submenuElement = document.querySelectorAll(
        '.sidebar-main .nav-sidebar > .nav-item-submenu',
      )

    // Check if submenu is still in the viewport
    function isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    // Toggle small sidebar
    if (sidebarMainToggler) {
      sidebarMainToggler.forEach(function (toggler) {
        toggler.addEventListener('click', function (e) {
          e.preventDefault()
          sidebarMainElement.classList.toggle(resizeClass)
          sidebarMainElement.classList.remove(sidebarMobileTogglerClass)
          !sidebarMainElement.classList.contains(resizeClass) &&
            sidebarMainElement.classList.remove(resizeClass)
        })
      })
    }

    // Configure submenus
    if (submenuElement) {
      submenuElement.forEach(function (toggle) {
        const sub = toggle.querySelector(`:scope > .${navSubmenuClass}`)

        // Display submenu on mouseenter
        toggle.addEventListener('mouseenter', function () {
          if (sidebarMainElement.classList.contains(resizeClass)) {
            this.classList.add(submenuToggleClass)
            this.querySelector(':scope > .nav-link').classList.add('pe-none')
            !isInViewport(sub) && sub.classList.add(navSubmenuReversedClass)
          }
        })

        // Hide submenu on mouse leave
        toggle.addEventListener('mouseleave', function () {
          this.classList.remove(submenuToggleClass)
          this.querySelector(':scope > .nav-link').classList.remove('pe-none')
          sub.classList.remove(navSubmenuReversedClass)
        })
      })
    }
  }

  // Toggle main sidebar
  const sidebarMainToggle = function () {
    // Elements
    const sidebarMainElement = document.querySelector('.sidebar-main')
    const sidebarMainRestElements = document.querySelectorAll(
      '.sidebar:not(.sidebar-main)',
    )
    const sidebarMainDesktopToggler = document.querySelectorAll(
      '.sidebar-main-toggle',
    )
    const sidebarMainMobileToggler = document.querySelectorAll(
      '.sidebar-mobile-main-toggle',
    )
    const sidebarCollapsedClass = 'sidebar-collapsed'
    const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

    // On desktop
    sidebarMainDesktopToggler.forEach(function (toggler) {
      toggler.addEventListener('click', function (e) {
        e.preventDefault()
        sidebarMainElement.classList.toggle(sidebarCollapsedClass)
      })
    })

    // On mobile
    sidebarMainMobileToggler.forEach(function (toggler) {
      toggler.addEventListener('click', function (e) {
        e.preventDefault()
        sidebarMainElement.classList.toggle(sidebarMobileExpandedClass)

        sidebarMainRestElements.forEach(function (sidebars) {
          sidebars.classList.remove(sidebarMobileExpandedClass)
        })
      })
    })
  }

  // Toggle secondary sidebar
  const sidebarSecondaryToggle = function () {
    // Elements
    const sidebarSecondaryElement = document.querySelector('.sidebar-secondary')
    const sidebarSecondaryRestElements = document.querySelectorAll(
      '.sidebar:not(.sidebar-secondary)',
    )
    const sidebarSecondaryDesktopToggler = document.querySelectorAll(
      '.sidebar-secondary-toggle',
    )
    const sidebarSecondaryMobileToggler = document.querySelectorAll(
      '.sidebar-mobile-secondary-toggle',
    )
    const sidebarCollapsedClass = 'sidebar-collapsed'
    const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

    // On desktop
    sidebarSecondaryDesktopToggler.forEach(function (toggler) {
      toggler.addEventListener('click', function (e) {
        e.preventDefault()
        sidebarSecondaryElement.classList.toggle(sidebarCollapsedClass)
      })
    })

    // On mobile
    sidebarSecondaryMobileToggler.forEach(function (toggler) {
      toggler.addEventListener('click', function (e) {
        e.preventDefault()
        sidebarSecondaryElement.classList.toggle(sidebarMobileExpandedClass)

        sidebarSecondaryRestElements.forEach(function (sidebars) {
          sidebars.classList.remove(sidebarMobileExpandedClass)
        })
      })
    })
  }

  // Toggle right sidebar
  const sidebarRightToggle = function () {
    // Elements
    const sidebarRightElement = document.querySelector('.sidebar-end')
    const sidebarRightRestElements = document.querySelectorAll(
      '.sidebar:not(.sidebar-end)',
    )
    const sidebarRightDesktopToggler = document.querySelectorAll(
      '.sidebar-end-toggle',
    )
    const sidebarRightMobileToggler = document.querySelectorAll(
      '.sidebar-mobile-end-toggle',
    )
    const sidebarCollapsedClass = 'sidebar-collapsed'
    const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

    // On desktop
    sidebarRightDesktopToggler.forEach(function (toggler) {
      toggler.addEventListener('click', function (e) {
        e.preventDefault()
        sidebarRightElement.classList.toggle(sidebarCollapsedClass)
      })
    })

    // On mobile
    sidebarRightMobileToggler.forEach(function (toggler) {
      toggler.addEventListener('click', function (e) {
        e.preventDefault()
        sidebarRightElement.classList.toggle(sidebarMobileExpandedClass)

        sidebarRightRestElements.forEach(function (sidebars) {
          sidebars.classList.remove(sidebarMobileExpandedClass)
        })
      })
    })
  }

  // Sidebar navigation
  const navigationSidebar = function () {
    // Elements
    const navContainerClass = 'nav-sidebar'
    const navItemOpenClass = 'nav-item-open'
    const navLinkClass = 'nav-link'
    const navLinkDisabledClass = 'disabled'
    const navSubmenuContainerClass = 'nav-item-submenu'
    const navSubmenuClass = 'nav-group-sub'
    const navScrollSpyClass = 'nav-scrollspy'
    const sidebarNavElement = document.querySelectorAll(
      `.${navContainerClass}:not(.${navScrollSpyClass})`,
    )

    // Setup
    sidebarNavElement.forEach(function (nav) {
      nav
        .querySelectorAll(
          `.${navSubmenuContainerClass} > .${navLinkClass}:not(.${navLinkDisabledClass})`,
        )
        .forEach(function (link) {
          link.addEventListener('click', function (e) {
            e.preventDefault()
            const submenuContainer = link.closest(
              `.${navSubmenuContainerClass}`,
            )
            const submenu = link
              .closest(`.${navSubmenuContainerClass}`)
              .querySelector(`:scope > .${navSubmenuClass}`)

            // Collapsible
            if (submenuContainer.classList.contains(navItemOpenClass)) {
              new bootstrap.Collapse(submenu).hide()
              submenuContainer.classList.remove(navItemOpenClass)
            } else {
              new bootstrap.Collapse(submenu).show()
              submenuContainer.classList.add(navItemOpenClass)
            }

            // Accordion
            if (
              link
                .closest(`.${navContainerClass}`)
                .getAttribute('data-nav-type') === 'accordion'
            ) {
              for (let sibling of link.parentNode.parentNode.children) {
                if (
                  sibling !== link.parentNode &&
                  sibling.classList.contains(navItemOpenClass)
                ) {
                  sibling
                    .querySelectorAll(`:scope > .${navSubmenuClass}`)
                    // eslint-disable-next-line no-shadow
                    .forEach(function (submenu) {
                      new bootstrap.Collapse(submenu).hide()
                      sibling.classList.remove(navItemOpenClass)
                    })
                }
              }
            }
          })
        })
    })
  }
  // Components
  // -------------------------

  // Tooltip
  const componentTooltip = function () {
    const tooltipSelector = document.querySelectorAll(
      '[data-bs-popup="tooltip"]',
    )

    tooltipSelector.forEach(function (popup) {
      new bootstrap.Tooltip(popup, {
        boundary: '.page-content',
      })
    })
  }

  // Popover
  const componentPopover = function () {
    const popoverSelector = document.querySelectorAll(
      '[data-bs-popup="popover"]',
    )

    popoverSelector.forEach(function (popup) {
      new bootstrap.Popover(popup, {
        boundary: '.page-content',
      })
    })
  }

  // "Go to top" button
  const componentToTopButton = function () {
    // Elements
    const toTopContainer = document.querySelector('.content-wrapper')
    const toTopElement = document.createElement('button')
    const toTopElementIcon = document.createElement('i')
    const toTopButtonContainer = document.createElement('div')
    const toTopButtonColorClass = 'btn-secondary'
    const toTopButtonIconClass = 'ph-arrow-up'
    const scrollableContainer = document.querySelector('.content-inner')
    const scrollableDistance = 250
    const footerContainer = document.querySelector('.navbar-footer')

    // Append only if container exists
    if (scrollableContainer) {
      // Create button container
      toTopContainer.appendChild(toTopButtonContainer)
      toTopButtonContainer.classList.add('btn-to-top')

      // Create button
      toTopElement.classList.add(
        'btn',
        toTopButtonColorClass,
        'btn-icon',
        'rounded-pill',
      )
      toTopElement.setAttribute('type', 'button')
      toTopButtonContainer.appendChild(toTopElement)
      toTopElementIcon.classList.add(toTopButtonIconClass)
      toTopElement.appendChild(toTopElementIcon)

      // Show and hide on scroll
      const toTopButton = document.querySelector('.btn-to-top')
      const addClassOnScroll = () =>
        toTopButton.classList.add('btn-to-top-visible')
      const removeClassOnScroll = () =>
        toTopButton.classList.remove('btn-to-top-visible')

      scrollableContainer.addEventListener('scroll', function () {
        const scrollpos = scrollableContainer.scrollTop
        scrollpos >= scrollableDistance
          ? addClassOnScroll()
          : removeClassOnScroll()
        if (footerContainer) {
          if (
            this.scrollHeight - this.scrollTop - this.clientHeight <=
            footerContainer.clientHeight
          ) {
            toTopButton.style.bottom = `${footerContainer.clientHeight + 20}px`
          } else {
            toTopButton.removeAttribute('style')
          }
        }
      })

      // Scroll to top on click
      document
        .querySelector('.btn-to-top .btn')
        .addEventListener('click', function () {
          scrollableContainer.scrollTo(0, 0)
        })
    }
  }

  // Misc
  // -------------------------

  // Dropdown submenus. Trigger on click
  const dropdownSubmenu = function () {
    // Classes
    const menuClass = 'dropdown-menu'
    const submenuClass = 'dropdown-submenu'
    const menuToggleClass = 'dropdown-toggle'
    const disabledClass = 'disabled'
    const showClass = 'show'

    if (submenuClass) {
      // Toggle submenus on all levels
      document
        .querySelectorAll(
          `.${menuClass} .${submenuClass}:not(.${disabledClass}) .${menuToggleClass}`,
        )
        .forEach(function (link) {
          link.addEventListener('click', function (e) {
            e.stopPropagation()
            e.preventDefault()

            // Toggle classes
            link.closest(`.${submenuClass}`).classList.toggle(showClass)
            link
              .closest(`.${submenuClass}`)
              .querySelectorAll(`:scope > .${menuClass}`)
              .forEach(function (children) {
                children.classList.toggle(showClass)
              })

            // When submenu is shown, hide others in all siblings
            for (const sibling of link.parentNode.parentNode.children) {
              if (sibling !== link.parentNode) {
                sibling.classList.remove(showClass)
                sibling
                  .querySelectorAll(`.${showClass}`)
                  .forEach(function (submenu) {
                    submenu.classList.remove(showClass)
                  })
              }
            }
          })
        })

      // Hide all levels when parent dropdown is closed
      document.querySelectorAll(`.${menuClass}`).forEach(function (link) {
        if (!link.parentElement.classList.contains(submenuClass)) {
          link.parentElement.addEventListener(
            'hidden.bs.dropdown',
            function (e) {
              link
                .querySelectorAll(`.${menuClass}.${showClass}`)
                .forEach(function (children) {
                  children.classList.remove(showClass)
                })
            },
          )
        }
      })
    }
  }

  //
  // Return objects assigned to module
  //

  return {
    // Disable transitions before page is fully loaded
    initBeforeLoad() {
      detectOS()
      transitionsDisabled()
    },

    // Initialize all components
    initComponents() {
      componentTooltip()
      componentPopover()
      componentToTopButton()
    },

    // Dropdown submenu
    initDropdowns() {
      dropdownSubmenu()
    },

    // Initialize core
    initCore() {
      App.initSidebars()
      App.initNavigations()
      App.initBeforeLoad()
      App.initComponents()
      App.initDropdowns()
    },

    // Enable transitions when page is fully loaded
    initAfterLoad() {
      transitionsEnabled()
    },

    // Initialize all sidebars
    initSidebars() {
      sidebarMainResize()
      sidebarMainToggle()
      sidebarSecondaryToggle()
      sidebarRightToggle()
    },

    // Initialize all navigations
    initNavigations() {
      navigationSidebar()
    },
  }
})()

// Initialize module
// ------------------------------

// When content is loaded
document.addEventListener('DOMContentLoaded', function () {
  App.initCore()
})

// When page is fully loaded
window.addEventListener('load', function () {
  App.initAfterLoad()
})
