export default {
  state: {
    background: 'background-01.jpg',
    pageTitle: 'GSN Digital Budget Module',
  },

  mutations: {
    SET_BACKGROUND(state, background) {
      state.background = background
    },
    SET_PAGETITLE(state, pageTitle) {
      state.pageTitle = pageTitle
    },
  },

  actions: {
    setBackground({ commit }, background) {
      commit('SET_BACKGROUND', background)
    },
    setPageTitle({ commit }, title) {
      commit('SET_PAGETITLE', title)
    },
  },

  getters: {
    background: (state) => state.background,
    pageTitle: (state) => state.pageTitle,
  },
}
