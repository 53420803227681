import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getCenterCostsLaborCost({ commit }, companyCode) {
      try {
        const response = await api.get(
          `/flows/${companyCode}/data/labor-costs/center-costs`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getLaborCostBudget({ commit }, companyCode) {
      try {
        const response = await api.get(`/flows/${companyCode}/data/labor-costs`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async deleteFormLaborCost({ commit }, data) {
      try {
        const response = await api.delete(
          `/flows/${data.companyCode}/data/labor-costs/${data.id}`,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async saveFormLaborCost({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/labor-costs`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async finishLaborCostForm({ commit }, data) {
      try {
        const response = await api.put(
          `/flows/${data.companyCode}/data/labor-costs/finish`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
